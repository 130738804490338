import Vue from "vue";
import Router from "vue-router";

// const ADMIN_ROLE = 4;
// const RESTAURANT_ROLE = 3;

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/RestaurantDashboard",
          beforeEnter: (to, from, next) => {
            if (
              JSON.parse(localStorage.getItem("currentUser")) &&
              JSON.parse(localStorage.getItem("isPhoneVerified"))
            ) {
              var test = JSON.parse(localStorage.getItem("currentUser"));
              let isPhoneVerified = JSON.parse(
                localStorage.getItem("isPhoneVerified")
              );
              let check = test.roles.filter(x => x.title === "Business Owner");
              if (check.length === 0 && isPhoneVerified === false)
                next({ name: "login" });
              else next();
            } else {
              next({ name: "login" });
            }
          },
          name: "RestaurantDashboard",
          component: () => import("@/view/pages/RestaurantDashboard.vue")
        },
        {
          path: "/analytics",
          beforeEnter: (to, from, next) => {
            if (
              JSON.parse(localStorage.getItem("currentUser")) &&
              JSON.parse(localStorage.getItem("isPhoneVerified"))
            ) {
              var test = JSON.parse(localStorage.getItem("currentUser"));
              let isPhoneVerified = JSON.parse(
                localStorage.getItem("isPhoneVerified")
              );
              let check = test.roles.filter(
                x => x.title === "System Administrator"
              );
              if (check.length === 0 && isPhoneVerified === false)
                next({ name: "login" });
              else next();
            } else {
              next({ name: "login" });
            }
          },
          name: "Analytics",
          component: () => import("@/view/pages/Analytics.vue")
        },
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
          beforeEnter: (to, from, next) => {
            if (
              JSON.parse(localStorage.getItem("currentUser")) &&
              JSON.parse(localStorage.getItem("isPhoneVerified"))
            ) {
              var test = JSON.parse(localStorage.getItem("currentUser"));
              let isPhoneVerified = JSON.parse(
                localStorage.getItem("isPhoneVerified")
              );
              let check = test.roles.filter(
                x => x.title === "System Administrator"
              );
              if (check.length === 0 && isPhoneVerified === false)
                next({ name: "login" });
              else next();
            } else {
              next({ name: "login" });
            }
          }
        },
        {
          path: "/custom_notifications",
          name: "customer_notifications",
          component: () => import("@/view/pages/CustomNotifications.vue"),
          beforeEnter: (to, from, next) => {
            if (
              JSON.parse(localStorage.getItem("currentUser")) &&
              JSON.parse(localStorage.getItem("isPhoneVerified"))
            ) {
              var test = JSON.parse(localStorage.getItem("currentUser"));
              let isPhoneVerified = JSON.parse(
                localStorage.getItem("isPhoneVerified")
              );
              let check = test.roles.filter(
                x =>
                  x.title === "System Administrator" ||
                  x.title === "Business Owner"
              );
              if (check.length === 0 && isPhoneVerified === false)
                next({ name: "login" });
              else next();
            } else {
              next({ name: "login" });
            }
          }
        },
        {
          path: "/notificationslist",
          name: "notificationslist",
          component: () => import("@/view/pages/NotificationsList.vue"),
          beforeEnter: (to, from, next) => {
            if (
              JSON.parse(localStorage.getItem("currentUser")) &&
              JSON.parse(localStorage.getItem("isPhoneVerified"))
            ) {
              var test = JSON.parse(localStorage.getItem("currentUser"));
              let isPhoneVerified = JSON.parse(
                localStorage.getItem("isPhoneVerified")
              );
              let check = test.roles.filter(x => x.title === "Business Owner");
              if (check.length === 0 && isPhoneVerified === false)
                next({ name: "login" });
              else next();
            } else {
              next({ name: "login" });
            }
          }
        },
        {
          path: "/orders_list",
          name: "orders_list",
          component: () => import("@/view/pages/OrdersList.vue"),
          beforeEnter: (to, from, next) => {
            if (
              JSON.parse(localStorage.getItem("currentUser")) &&
              JSON.parse(localStorage.getItem("isPhoneVerified"))
            ) {
              var test = JSON.parse(localStorage.getItem("currentUser"));
              let isPhoneVerified = JSON.parse(
                localStorage.getItem("isPhoneVerified")
              );
              let check = test.roles.filter(
                x =>
                  x.title === "System Administrator" ||
                  x.title === "Business Owner"
              );
              if (check.length === 0 && isPhoneVerified === false)
                next({ name: "login" });
              else next();
            } else {
              next({ name: "login" });
            }
          }
        },
        {
          path: "/all_restaurants",
          name: "all_restaurants",
          pathToRegexpOptions: { strict: true },
          component: () => import("@/view/pages/AllRestaurants.vue"),
          beforeEnter: (to, from, next) => {
            if (
              JSON.parse(localStorage.getItem("currentUser")) &&
              JSON.parse(localStorage.getItem("isPhoneVerified"))
            ) {
              var test = JSON.parse(localStorage.getItem("currentUser"));
              let isPhoneVerified = JSON.parse(
                localStorage.getItem("isPhoneVerified")
              );
              let check = test.roles.filter(
                x =>
                  x.title === "System Administrator" ||
                  x.title === "Business Owner"
              );
              if (check.length === 0 && isPhoneVerified === false)
                next({ name: "login" });
              else next();
            } else {
              next({ name: "login" });
            }
          }
        },
        {
          path: "/add_restaurant",
          name: "add_restaurant",
          component: () => import("@/view/pages/AddRestaurant.vue"),
          beforeEnter: (to, from, next) => {
            if (
              JSON.parse(localStorage.getItem("currentUser")) &&
              JSON.parse(localStorage.getItem("isPhoneVerified"))
            ) {
              var test = JSON.parse(localStorage.getItem("currentUser"));
              let isPhoneVerified = JSON.parse(
                localStorage.getItem("isPhoneVerified")
              );
              let check = test.roles.filter(
                x => x.title === "System Administrator"
              );
              if (check.length === 0 && isPhoneVerified === false)
                next({ name: "login" });
              else next();
            } else {
              next({ name: "login" });
            }
          }
        },
        {
          path: "/system_settings",
          name: "system_settings",
          component: () => import("@/view/pages/SystemSettings.vue"),
          beforeEnter: (to, from, next) => {
            if (
              JSON.parse(localStorage.getItem("currentUser")) &&
              JSON.parse(localStorage.getItem("isPhoneVerified"))
            ) {
              var test = JSON.parse(localStorage.getItem("currentUser"));
              let isPhoneVerified = JSON.parse(
                localStorage.getItem("isPhoneVerified")
              );
              let check = test.roles.filter(
                x => x.title === "System Administrator"
              );
              if (check.length === 0 && isPhoneVerified === false)
                next({ name: "login" });
              else next();
            } else {
              next({ name: "login" });
            }
          }
        },
        {
          path: "/owners_list",
          name: "owners_list",
          component: () => import("@/view/pages/OwnersList.vue"),
          beforeEnter: (to, from, next) => {
            if (
              JSON.parse(localStorage.getItem("currentUser")) &&
              JSON.parse(localStorage.getItem("isPhoneVerified"))
            ) {
              var test = JSON.parse(localStorage.getItem("currentUser"));
              let isPhoneVerified = JSON.parse(
                localStorage.getItem("isPhoneVerified")
              );
              let check = test.roles.filter(
                x => x.title === "System Administrator"
              );
              if (check.length === 0 && isPhoneVerified === false)
                next({ name: "login" });
              else next();
            } else {
              next({ name: "login" });
            }
          }
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/view/pages/Builder.vue"),
          beforeEnter: (to, from, next) => {
            if (
              JSON.parse(localStorage.getItem("currentUser")) &&
              JSON.parse(localStorage.getItem("isPhoneVerified"))
            ) {
              var test = JSON.parse(localStorage.getItem("currentUser"));
              let isPhoneVerified = JSON.parse(
                localStorage.getItem("isPhoneVerified")
              );
              let check = test.roles.filter(
                x => x.title === "System Administrator"
              );
              if (check.length === 0 && isPhoneVerified === false)
                next({ name: "login" });
              else next();
            } else {
              next({ name: "login" });
            }
          }
        },
        {
          path: "/users_list",
          name: "users_list",
          component: () => import("@/view/pages/UsersList.vue"),
          beforeEnter: (to, from, next) => {
            if (
              JSON.parse(localStorage.getItem("currentUser")) &&
              JSON.parse(localStorage.getItem("isPhoneVerified"))
            ) {
              var test = JSON.parse(localStorage.getItem("currentUser"));
              let isPhoneVerified = JSON.parse(
                localStorage.getItem("isPhoneVerified")
              );
              let check = test.roles.filter(
                x => x.title === "System Administrator"
              );
              if (check.length === 0 && isPhoneVerified === false)
                next({ name: "login" });
              else next();
            } else {
              next({ name: "login" });
            }
          }
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/Profile.vue")
        },
        // {
        //   path: "/restaurant_setting",
        //   name: "restaurant_setting",
        //   component: () => import("@/view/pages/RestaurantSetting.vue")
        // },
        {
          path: "/treeview_test",
          name: "treeview_test",
          component: () => import("@/view/pages/TreeViewTest.vue")
        },
        {
          path: "/restaurant_setting",
          name: "restaurant_setting",
          component: () => import("@/view/pages/RestaurantSettingMenu.vue")
        },
        {
          path: "/general_settings",
          name: "restaurant_setting_general",
          component: () => import("@/view/pages/RestaurantSettingGeneral.vue")
        },
        {
          path: "/management",
          name: "management",
          component: () => import("@/view/pages/Management.vue")
        },
        {
          path: "/kitchen_management",
          name: "kitchen_management",
          component: () => import("@/view/pages/KitchenManagement.vue")
        },
        {
          path: "/staff_management",
          name: "staff_management",
          component: () => import("@/view/pages/StaffManagement.vue")
        },
        {
          path: "/tables_management",
          name: "tables_management",
          component: () => import("@/view/pages/TablesManagement.vue")
        },
        {
          path: "/printer_management",
          name: "printer_management",
          component: () => import("@/view/pages/PrinterManagement.vue")
        },
        {
          path: "/allergens",
          name: "allergens",
          component: () => import("@/view/pages/Allergens.vue")
        },
        {
          path: "/menu_group",
          name: "menu_group",
          component: () => import("@/view/pages/MenuGroup.vue")
        },
        {
          path: "/menu_category",
          name: "menu_category",
          component: () => import("@/view/pages/MenuCategory.vue")
        },
        {
          path: "/menu_item",
          name: "menu_item",
          component: () => import("@/view/pages/MenuItem.vue")
        },
        {
          path: "/menu_extra_types",
          name: "menu_extra_types",
          component: () => import("@/view/pages/MenuExtraTypes.vue")
        },
        {
          path: "/menu_item_extra",
          name: "menu_item_extra",
          component: () => import("@/view/pages/MenuItemExtra.vue")
        },
        {
          path: "/reservation_slots",
          name: "reservation_slots",
          component: () => import("@/view/pages/ReservationSlots.vue")
        },
        {
          path: "/local_offers",
          name: "local_offers",
          component: () => import("@/view/pages/LocalOffers.vue")
        },
        {
          path: "/promo_codes",
          name: "promo_codes",
          component: () => import("@/view/pages/PromoCodes.vue")
        },
        {
          path: "/reviews",
          name: "reviews",
          component: () => import("@/view/pages/Reviews.vue"),
          beforeEnter: (to, from, next) => {
            if (
              JSON.parse(localStorage.getItem("currentUser")) &&
              JSON.parse(localStorage.getItem("isPhoneVerified"))
            ) {
              var test = JSON.parse(localStorage.getItem("currentUser"));
              let isPhoneVerified = JSON.parse(
                localStorage.getItem("isPhoneVerified")
              );
              let check = test.roles.filter(
                x => x.title === "System Administrator"
              );
              if (check.length === 0 && isPhoneVerified === false)
                next({ name: "login" });
              else next();
            } else {
              next({ name: "login" });
            }
          }
        },
        {
          path: "/order_details",
          name: "orderdetails",
          component: () => import("@/view/pages/OrderDetails.vue")
        },
        {
          path: "/add_merchant_accounts",
          name: "addMerchantAccounts",
          component: () => import("@/view/pages/MerchantAccounts.vue"),
          beforeEnter: (to, from, next) => {
            if (
              JSON.parse(localStorage.getItem("currentUser")) &&
              JSON.parse(localStorage.getItem("isPhoneVerified"))
            ) {
              var test = JSON.parse(localStorage.getItem("currentUser"));
              let isPhoneVerified = JSON.parse(
                localStorage.getItem("isPhoneVerified")
              );
              let check = test.roles.filter(
                x => x.title === "System Administrator"
              );
              if (check.length === 0 && isPhoneVerified === false)
                next({ name: "login" });
              else next();
            } else {
              next({ name: "login" });
            }
          }
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});

export default router;
