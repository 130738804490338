import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
//import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
window.$ = window.jQuery = require("jquery");
Vue.config.productionTip = false;
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);
// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
//bootstrap css
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Pusher from "pusher-js";
import Print from "vue-print-nb";

Vue.use(Print);
// API service init
ApiService.init();

// Remove this to disable mock API
//MockService.init();

// var path = "https://api.connectsmartserve.com/api/v1";
// var path = "http://192.168.18.70:8000/api/v1";
// var path = "http://127.0.0.1:8001/api/v1";
var path = "https://mashghol.com/smartseve-api/public/api/v1";

// var path =
// window.location.protocol +
//   "//" +
//   window.location.hostname +
//   "/smartseve-api/public/api/v1/admin";

Vue.prototype.$path = path;
// localStorage.clear();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
//firebase
import firebase from "firebase";
var firebaseConfig = {
  apiKey: "AIzaSyA7b-APN09JLiCABZII4f_C7ZfhuJVJwmg",
  authDomain: "smartserve-6905e.firebaseapp.com",
  projectId: "smartserve-6905e",
  storageBucket: "smartserve-6905e.appspot.com",
  messagingSenderId: "735905919060",
  appId: "1:735905919060:web:078ap037df8ab9f2f3723dc1",
  measurementId: "G-SWXB5BNTJX"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

if (localStorage.getItem("currentUser")) {
  if (JSON.parse(localStorage.getItem("currentUser")).restaurant) {
    let restaurantUUID = JSON.parse(localStorage.getItem("currentUser"))
      .restaurant.uuid;

    // Pusher Connection
    let pusher = new Pusher("08a7c6e614cf0fcc01ac", {
      authEndpoint: path + "/pos/subscribe/event/" + restaurantUUID,
      cluster: "ap2",
      encrypted: true
    });

    // Pusher Subscribe Channela
    Vue.prototype.$channel = pusher.subscribe(
      "private-table-seat-channel-" + restaurantUUID
    );
  }
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
