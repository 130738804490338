import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const UPDATE_USER_MUTATION = "setUpdateUserDetail";

const state = {
  errors: [],
  user: {},
  userDetail: localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser"))
    : {},
  isAuthenticated: !!JwtService.getToken(),
  isPhoneVerified: localStorage.getItem("isPhoneVerified")
    ? JSON.parse(localStorage.getItem("isPhoneVerified"))
    : false
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  updatedUser(state) {
    return state.userDetail;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("/admin/auth/authenticate", credentials)
        .then(({ data }) => {
          localStorage.setItem("currentUser", JSON.stringify(data.data));
          localStorage.setItem("isPhoneVerified", JSON.stringify(false));
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          // console.log(response);
          if (response.data.message === "Server Error") {
            context.commit(SET_ERROR, ["Invalid Credentials"]);
          }
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
    localStorage.removeItem("currentUser");
    localStorage.removeItem("restaurantDetails");
    localStorage.removeItem("isPhoneVerified");
  },
  // [REGISTER](context, credentials) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.post("users", { user: credentials })
  //       .then(({ data }) => {
  //         context.commit(SET_AUTH, data);
  //         resolve(data);
  //       })
  //       .catch(({ response }) => {
  //         context.commit(SET_ERROR, response.data.errors);
  //         reject(response);
  //       });
  //   });
  // },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      // ApiService.get("login_user_details")
      //   .then(() => {
      //     // context.commit(SET_AUTH, data);
      //   })
      //   .catch(() => {});
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_USER](context, payload) {
    context.commit(UPDATE_USER_MUTATION, payload);
    return payload;
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.userDetail = localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser"))
      : {};
    state.isPhoneVerified = localStorage.getItem("isPhoneVerified")
      ? JSON.parse(localStorage.getItem("isPhoneVerified"))
      : false;
    state.errors = [];
    JwtService.saveToken("Bearer " + state.user.data.access_token);
  },
  [UPDATE_USER_MUTATION](state, payload) {
    state.userDetail = payload;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.isPhoneVerified = false;
    state.user = {};
    state.userDetail = {};
    state.errors = [];
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
