import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    // Vue.axios.defaults.baseURL =
    //   window.location.protocol +
    //   "//" +
    //   window.location.hostname +
    //   "/dreamio/api/";

    // Vue.axios.defaults.baseURL = "http://192.168.18.70:8000/api/v1";
    // window.location.protocol +
    // "//" +
    // window.location.hostname +
    // ":8000/api/v1";
    // Vue.axios.defaults.baseURL = "http://192.168.18.70:8000/api/v1";
    // Vue.axios.defaults.baseURL = "https://api.connectsmartserve.com/api/v1";

    // Vue.axios.defaults.baseURL = "http://127.0.0.1:8001/api/v1";
    Vue.axios.defaults.baseURL =
      "https://mashghol.com/smartseve-api/public/api/v1";
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {*}
   */
  get(resource, params, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`, { params }).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource);
  }
};

export default ApiService;
